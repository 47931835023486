/**
 * Wraps tanstack's `useQueryClient` and insert some utils fns to improve DX
 * of `useInfiniteQuery`
 */
import { useQueryClient as useTanstackQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

const getPagesArr = (stored) => {
  if (!stored) {
    return [];
  }

  if ('pages' in stored) {
    return stored.pages;
  }

  return [stored];
};

const setInfiniteQueryData = (client) => (queryKey, updater) => {
  if (typeof updater !== 'function') {
    client.setQueryData(queryKey, updater);
    return;
  }

  client.setQueryData(queryKey, (stored) => {
    const result = updater(getPagesArr(stored));

    return { pages: result, pageParams: stored?.pageParams ?? [] };
  });
};

const getInfiniteQueryData = (client) => (queryKey) => {
  const data = client.getQueryData(queryKey);

  return data.pages ?? data;
};

const useQueryClient = () => {
  const tanstackClient = useTanstackQueryClient();

  const [client] = useState(() => ({
    fetchQuery: (...args) => tanstackClient.fetchQuery(...args),
    getInfiniteQueryData: getInfiniteQueryData(tanstackClient),
    getQueryData: (...args) => tanstackClient.getQueryData(...args),
    refetchQueries: (...args) => tanstackClient.refetchQueries(...args),
    removeQueries: (...args) => tanstackClient.removeQueries(...args),
    setInfiniteQueryData: setInfiniteQueryData(tanstackClient),
    setQueryData: (...args) => tanstackClient.setQueryData(...args),
  }));

  return client;
};

export default useQueryClient;
